import { colors } from "./colors";
import * as variants from "./variants";
import * as type from "./type";

const defaultTheme = {
  // breakpoints: ["768px", "992px","1200px", "1400px"],
  // // breakpoints: ["24em", "40em", "48em", "64em", "80em", "112em"],
  // colors,
  // ...variants,
  // ...type,
  // layout: {
  //   container: {
  //     px: [3, 4],
  //     py: 3,
  //     width: "100%",
  //     minWidth: 0,
  //     maxWidth: "1024px",
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // },
  // styles: {
  //   root: {
  //     margin: "0",
  //     fontFamily: "body",
  //   },
  //   img: {
  //     maxWidth: "100%",
  //   },
  //   p: {
  //     fontSize: "1rem",
  //     lineHeight: "1.5",
  //   },
  //   fieldset: {
  //     border: "0",
  //   },
  //   small: {
  //     fontSize: "0.625rem",
  //   },
  //   'input[type="number"]': {
  //     appearance: "textField",
  //   },
  //   'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
  //     {
  //       appearance: "none",
  //       margin: 0,
  //     },
  // },
};

export default defaultTheme;
